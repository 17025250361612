<template>
  <section class="password">
    <form @submit.prevent="changePasswordAction" class="mt-20">
      <InputText
        :label="`${$t('data.password.current.label')}`"
        name="current-password"
        :placeholder="`${$t('data.password.current.placeholder')}`"
        type="password"
        class="w-full mb-8"
        :errorMsg="passwordErrMsg"
        :showErrMsg="$v.pwd.password.$error"
        @blur="$v.pwd.password.$touch()"
        v-model="pwd.password"
      />

      <InputText
        :label="`${$t('data.password.new.label')}`"
        name="new-password"
        :placeholder="`${$t('data.password.new.placeholder')}`"
        type="password"
        class="w-full mb-8"
        :errorMsg="newPasswordErrMsg"
        :showErrMsg="$v.pwd.newPassword.$error"
        @blur="$v.pwd.newPassword.$touch()"
        v-model="pwd.newPassword"
      />

      <InputText
        :label="`${$t('data.password.confirm.label')}`"
        name="confirm-password"
        :placeholder="`${$t('data.password.confirm.placeholder')}`"
        type="password"
        class="w-full mb-8"
        :errorMsg="confirmPasswordErrMsg"
        :showErrMsg="$v.pwd.confirmPassword.$error"
        @blur="$v.pwd.confirmPassword.$touch()"
        v-model="pwd.confirmPassword"
      />
      <ButtonPrimary
        type="submit"
        :loading="loading"
        :text="`${$t('data.password.button')}`"
        class="w-full"
      />
    </form>
  </section>
</template>

<script>
const { required, minLength, sameAs } = require("vuelidate/lib/validators");
import { mapActions } from "vuex";

export default {
  name: "AccountPassword",

  data() {
    return {
      pwd: {
        password: "",
        newPassword: "",
        confirmPassword: "",
      },
      loading: false,
    };
  },
  validations: {
    pwd: {
      password: {
        required,
      },
      newPassword: {
        required,
        minLength: minLength(8),
      },
      confirmPassword: {
        required,
        sameAs: sameAs("newPassword"),
      },
    },
  },
  computed: {
    passwordErrMsg() {
      let msg;
      if (!this.$v.pwd.password.required) {
        msg = this.$t("data.password.current.error"); //Password is required
      }
      return msg;
    },
    newPasswordErrMsg() {
      let msg;
      if (!this.$v.pwd.newPassword.required) {
        msg = this.$t("data.password.new.error_1"); //Password is required
      }
      if (!this.$v.pwd.newPassword.minLength) {
        let count = this.$v.pwd.newPassword.$params.minLength.min;
        msg = this.$tc("data.password.new.error_2", count); //Password must have at least ${count} characters
      }
      return msg;
    },
    confirmPasswordErrMsg() {
      let msg;
      if (!this.$v.pwd.confirmPassword.required) {
        msg = this.$t("data.password.confirm.error_1"); //Password is required
      }
      if (!this.$v.pwd.confirmPassword.sameAs) {
        msg = this.$t("data.password.confirm.error_2"); //Passwords must match
      }
      return msg;
    },
  },
  methods: {
    ...mapActions("user", ["changePassword"]),
    async changePasswordAction() {
      try {
        this.$v.pwd.$touch();
        if (this.$v.pwd.$invalid) {
          return false;
        }

        this.loading = true;
        const res = await this.changePassword(this.pwd);
        if (!res) return false;
        this.loading = false;
        this.$notify({
          type: "success",
          title: res.data.message,
        });
        this.pwd.password =
          this.pwd.newPassword =
          this.pwd.confirmPassword =
            "";

        this.$nextTick(() => {
          this.$v.pwd.$reset();
        });
      } catch (error) {
        this.$notify({
          type: "error",
          text: this.$t("data.password.error"),
        });
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
